import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { getDescriptionSociete } from "../../../Services/ContactService";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import DescriptionSociete from "../../../../components/DescriptionSociete/DescritionSociete";
import DescriptionModal from "../Modals/DescriptionModal";


const PresentationSociete = ({ snackbarShowMessage }) => {
  const [presentation, setPresentation] = useState("");
  const [loadingPresentation, setLoadingPresentation] = useState(false);
  const { idContact } = useParams();
  const [reloadDescription, setReloadDescription] = useState(false);
  const descriptionModalProps = { description: presentation, setReloadDescription, reloadDescription };

  React.useEffect(() => {
    let isActive = true;
    (async () => {
      setLoadingPresentation(true);
      try {
        let societeData = await getDescriptionSociete(idContact);

        if (!isActive) {
          return;
        }
        setPresentation(societeData.data.description_societe);
        setLoadingPresentation(false);
      } catch (error) {
        if (!isActive) {
          return;
        }
        setLoadingPresentation(false);
        snackbarShowMessage("Une erreur est survenue.", "error", 4000);
      }
    })();

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadDescription]);

  return (
    <>      
      <DescriptionModal {...descriptionModalProps} />
      <DescriptionSociete loading={loadingPresentation} description={presentation}></DescriptionSociete>
    </>
  )
}

export default withSnackbar(PresentationSociete);

