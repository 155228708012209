import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import {
  getAllCadidatSelections,
  deleteCandidatSelection,
  archiveCandidatSelection,
  exportCandidatsSelection,
} from "../Services/SelectionCandidatService";
import moment from "moment";
import { Archive, Clear, Edit, GetApp, Unarchive } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { updateObjectStateValue } from "../../utils/FunctionHelpers";
import SelectionModal from "../Dashboard/Modals/SelectionModal";
import { getUserData } from "../../utils/FunctionHelpers";
import DialogModal from "../../utils/DialogModal";
import fileDownload from "js-file-download";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import "./selectionStyle.css";
import { theme } from "../../utils/Theme";
import { handleOnViewColumnsChange, displayColumn } from "../../utils/viewColumns";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const useStyles = makeStyles({
  archiveButton: {
    color: "white",
    fontWeight: 500,
    backgroundColor: "rgb(17, 122, 167)",
    marginBottom: "10px",
    "&:hover": {
      backgroundColor: "rgb(17, 122, 167)",
    },
  },
  fileDownloadIcon: {
    color: "green",
  },
});

const ListSelections = ({ snackbarShowMessage, history }) => {
  const role = getUserData().role[0];
  const [reloadSelections, setReloadSelections] = useState(false);

  const [selectionsData, setSelectionData] = useState({
    isLoading: false,
    data: [],
    archived: false,
  });
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) =>
    theme.breakpoints.between(767, 1023)
  );
  const isLargeMode = useMediaQuery((theme) =>
    theme.breakpoints.between(1023, 2800)
  );


  const handleDeleteCandidatSelection = ({ selection }) => {
    setLoading(true)
    snackbarShowMessage("Suppresion de la sélection en cours...", "warning");
    deleteCandidatSelection(selection)
      .then((response) => {
        snackbarShowMessage("La sélection a été supprimée.", "success", 4000);
        setReloadSelections(!reloadSelections);
      })
      .catch((error) => {
        snackbarShowMessage(
          "Erreur de suppression de la sélection.",
          "error",
          2000
        );
      }).finally(() => setLoading(false));
  };

  const handleArchiveCandidatSelection = (selection, isArchived) => {
    snackbarShowMessage(
      `${isArchived ? "Archivage" : "Désarchivage"
      } de la sélection en cours...`,
      "warning"
    );
    archiveCandidatSelection(selection, { isArchived })
      .then((response) => {
        snackbarShowMessage(
          `La sélection a été ${isArchived ? "archivée" : "désarchivée"
          } avec succès.`,
          "success"
        );
        setReloadSelections(!reloadSelections);
      })
      .catch((error) => {
        snackbarShowMessage(
          `Erreur ${isArchived ? "d'archivage" : "de désarchivage"
          } de la sélection.`,
          "error",
          4000
        );
      });
  };

  const handleShowArchivedSelection = () => {
    updateObjectStateValue(setSelectionData, {
      archived: !selectionsData.archived,
    });
    setReloadSelections(!reloadSelections);
  };

  const handleShowDeleteButton = (selection) => {
    const dialogProps = {
      label: <Clear color="error" />,
      description: "Voulez vous supprimer la sélection?",
      title: "Supprimer la sélection?",
      closeLabel: "Annuler",
      confirmLabel: "Confirmer",
      loading,
      onConfirm: handleDeleteCandidatSelection,
      data: {
        selection,
      },
    };
    return <>{role === "ROLE_ADMIN" ? <DialogModal {...dialogProps} /> : ""}</>;
  };

  const handleShowArchiveButton = (selection, isArchived) => {
    return (
      <IconButton
        data-toggle="tooltip"
        title={isArchived ? "Désarchiver" : "Archiver"}
        onClick={() => handleArchiveCandidatSelection(selection, !isArchived)}
      >
        {isArchived ? <Unarchive color="error" /> : <Archive color="primary" />}
      </IconButton>
    );
  };

  const handleUpdateLodingExport = (id, isExporting) => {
    const selectionsFinal = selectionsData.data.map((selection) => {
      if (selection.id === id) {
        return { ...selection, isExporting };
      }
      return selection;
    });
    updateObjectStateValue(setSelectionData, { data: selectionsFinal });
  };

  const handleDownload = (selection, selectionName) => {
    handleUpdateLodingExport(selection, true);
    exportCandidatsSelection(selection)
      .then(({ data }) => {
        fileDownload(data, selectionName + "-export.xlsx");
      })
      .catch(() => {
        snackbarShowMessage("Erreur d'export de la sélection.", "error", 2000);
      })
      .finally(() => {
        handleUpdateLodingExport(selection, false);
      });
  };

  const handleShowExportButton = (selection, selectionName, isExporting) => {
    return (
      <IconButton
        disabled={isExporting}
        key={selection}
        onClick={() => handleDownload(selection, selectionName)}
        data-toggle="tooltip"
        title="Télécharger"
      >
        {isExporting ? (
          <CircularProgress size={20} />
        ) : (
          <GetApp className={classes.fileDownloadIcon} />
        )}
      </IconButton>
    );
  };



  useEffect(() => {
    if (selectionsData.data.length > 0) {
      const selectionsFinal = selectionsData.data.map((selection) => {
        return { ...selection, isExporting: false };
      });
      updateObjectStateValue(setSelectionData, { data: selectionsFinal });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateObjectStateValue(setSelectionData, { isLoading: true, data: [] });
    getAllCadidatSelections(`?archived=${selectionsData.archived}`)
      .then(({ data }) => {
        updateObjectStateValue(setSelectionData, {
          isLoading: false,
          data: data.candidatsSelections,
        });
      })
      .catch(() => {
        snackbarShowMessage(
          "Erreur de chargement des sélections.",
          "error",
          4000
        );
      });
    return () => {
      updateObjectStateValue(setSelectionData, { isLoading: false, data: [] });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadSelections]);

  
  const tableName = "listSelectionsColumnVisibility";

  const columns = [
    {
      name: "id",
      label: "N°SELECTION",
      options: {
        display: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value}>
                {value.substring(0, 30).replace(/\D/g, "").substring(1, 7)}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "dateCreation",
      label: "DATE D'AJOUT",
      options: {
        display: displayColumn("dateCreation", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <>{moment(value.date).format("DD/MM/yyyy")}</>;
        },
      },
    },
    {
      name: "selectionName",
      label: "NOM SÉLECTION",
      options: {
        display: displayColumn("selectionName", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {value !== null ? value : "---"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "nom_mission",
      label: "MISSION",
      options: {
        display: displayColumn("nom_mission", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "250px",
              }}
            >
              {value !== null ? (
                <Link
                  to={{
                    pathname: `/societe/${tableMeta.rowData[8]}/missions/${tableMeta.rowData[7]}/view`,
                  }}
                  className="d-print-none font-sm"
                  style={{ textDecoration: "none" }}
                >
                  {value}
                </Link>
              ) : (
                "--"
              )}
            </Box>
          );
        },
      },
    },
    {
      name: "creator",
      label: "CONSULTANT",
      options: {
        display: displayColumn("creator", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                {value !== null ? value : "---"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "totalCandidats",
      label: "NBR",
      options: {
        display: displayColumn("totalCandidats", tableName),
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "50px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: `/selections/${tableMeta.rowData[0]}/candidats/list`,
                  search: `?selectionName=${tableMeta.rowData[2]}&consultant=${tableMeta.rowData[4]}`
                }}
                className="d-print-none font-sm"
                style={{ textDecoration: "none" }}
                onClick={() => window.localStorage.setItem('data_vivier_selected', JSON.stringify({
                  pathname: `/selections/${tableMeta.rowData[0]}/candidats/list`,
                  search: `?selectionName=${tableMeta.rowData[2]}&quantity=${value}&consultant=${tableMeta.rowData[4]}`
                }))}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    { name: "creatorId", options: { 
        display: false, } 
      },
    { name: "missionId", options: { 
        display: false, } 
      },
    { name: "societeId", options: { 
        display: false, } 
      },
    { name: "isArchived", options: { 
        display: false, } 
      },
    { name: "isExporting", options: { 
        display: false, } 
      },
    {
      name: "ACTIONS",
      options: {
        filter: false,
        sort: false,
        empty: true,
        setCellProps: () => ({ style: { whiteSpace: "nowrap" } }),
        setCellHeaderProps: () => ({ style: { textAlign: "center" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box sx={{ minWidth: "120px" }}>
              <Grid container>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  <SelectionModal
                    oldSelectionName={tableMeta.rowData[2]}
                    oldCreator={{
                      id: tableMeta.rowData[6],
                      value: tableMeta.rowData[4],
                    }}
                    oldMission={
                      tableMeta.rowData[7]
                        ? {
                          id: tableMeta.rowData[7],
                          value: tableMeta.rowData[3],
                        }
                        : ""
                    }
                    idSelection={tableMeta.rowData[0]}
                    setReloadSelections={setReloadSelections}
                    reloadSelections={reloadSelections}
                    isModify={true}
                    snackbarShowMessage={snackbarShowMessage}
                  >
                    {(handleOpen) => (
                      <IconButton
                        onClick={() => handleOpen()}
                        style={{ color: "#C8D148" }}
                        data-toggle="tooltip"
                        title="Modifier"
                      >
                        <Edit />
                      </IconButton>
                    )}
                  </SelectionModal>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  {getUserData().role.includes("ROLE_ADMIN") &&
                    handleShowArchiveButton(
                      tableMeta.rowData[0],
                      tableMeta.rowData[9]
                    )}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  {handleShowDeleteButton(tableMeta.rowData[0])}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                  {getUserData().role.includes("ROLE_ADMIN") &&
                    handleShowExportButton(
                      tableMeta.rowData[0],
                      tableMeta.rowData[2],
                      tableMeta.rowData[10]
                    )}
                </Grid>
              </Grid>
            </Box>
          );
        },
      },
    },
  ];


  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const tableBodyMaxHeight = `${isSmall
    ? viewportHeight - 292
    : isMediumMode
      ? viewportHeight - 298
      : isLargeMode
        ? viewportHeight - 255
        : 200}px`;

  const options = {
    jumpToPage: true,
    tableBodyMaxHeight: tableBodyMaxHeight,
    rowsPerPage: 50,
    selectableRows: "none",
    textLabels: {
      body: {
        noMatch: selectionsData.isLoading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
    sortOrder: {
      name: "selectionName",
      direction: "asc",
    },
  };


  return (
    <Box sx={{ padding: "0 20px " }}>
      <Grid container style={{ paddingBottom: "5px" }}>
        <Grid item xs={10} sm={6} md={6} xl={6}>
          <Box sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <h2
              className="text-capitalize"
              style={{ color: "rgb(17, 122, 167)" }}
            >
              MES SÉLECTIONS
            </h2>
          </Box>
        </Grid>
        <Grid item xs={2} sm={6} md={6} xl={6}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            {!isSmMode ? (
              <Button
                startIcon={
                  selectionsData.archived ? <RemoveIcon /> : <AddIcon />
                }
                onClick={handleShowArchivedSelection}
                className={classes.archiveButton}
              >
                LES SÉLECTIONS ARCHIVÉES
              </Button>
            ) : (

              <IconButton
                aria-label="action"
                onClick={handleShowArchivedSelection}
                size="medium"
                data-toggle="tooltip"
                title={selectionsData.archived ? "Retirer" : "Ajouter"}
              >
                {selectionsData.archived ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            )}
          </Box>
        </Grid>
      </Grid>
      <Box className="selection_container">
        <MUIDataTable
          data={selectionsData.data}
          columns={columns}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default withSnackbar(withRouter(ListSelections));
