import React, { useEffect, useRef } from "react";
import "./Listersociete.css";
import MUIDataTable from "mui-datatables";
import { CircularProgress, IconButton, useMediaQuery } from "@material-ui/core";
import { Box } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useState } from "react";
import { getUserData } from "../../utils/FunctionHelpers";
import { deleteSociete } from "../Services/SocieteService";
import DialogDeleteCustom from "../../components/DialogDeleteCustom/DialogDeleteCustom";
import { Delete } from "@material-ui/icons";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { handleOnViewColumnsChange, displayColumn } from "../../utils/viewColumns";

const ListerSociete = ({
  societesData,
  handleSearchSocietes,
  parameters,
  snackbarShowMessage
}) => {
  const history = useHistory();
  const mounted = useRef(false);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [loadToDelete, setLoadToDelete] = useState(false)
  const [societeSelectedToDelete, setSocieteSelectedToDelete] = useState(null)


  const handlePageChange = (params) => {
    handleSearchSocietes(params + 1);
  };
  const handleCloseDialog = () => {
    if (loadToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleOpenDialog = (societe) => {
    setSocieteSelectedToDelete({ id: societe.id, name: societe.name.charAt(0).toUpperCase() + societe.name.slice(1) });
    setOpenDialogDelete(true);
  }

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    loading: loadToDelete,
    title: "Supprimer la societe"
  }

  const handleDeleteSociete = async (uuid) => {
    try {
      setLoadToDelete(true);
      snackbarShowMessage("Patientez svp...", "warning", null);
      const data = await deleteSociete(uuid)
      handleSearchSocietes(societesData.pageSociete, parameters);
      snackbarShowMessage(data);
    } catch (error) {
      snackbarShowMessage("Erreur à la suppression de la societe", "error", 4000);
    } finally {
      setLoadToDelete(false);
      setOpenDialogDelete(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const tableName = "societeColumnVisibility";

  const columns = [
    {
      name: "uuid",
      label: "N° SOCIETE",
      options: {
        display: false,
        setCellProps: () => ({ style: { whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", maxWidth: "100px" } }),
      },
    },
    {
      name: "nom_societe",
      label: "NOM SOCIETE",
      options: {
        display: displayColumn("nom_societe", tableName),
        sort: true,
        setCellHeaderProps: () => ({ style: { justifyContent: "left" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "250px", textAlign: "left", marginLeft: "9%" }}>
                <Link to={{
                  pathname: `/societe/${tableMeta.rowData[0]}/view`,
                  state: { uuid: tableMeta.rowData[0] },
                }} >
                  {value}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT",
      options: {
        display: displayColumn("statut", tableName),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px", textAlign: "left", textTransform: "capitalize" }}>
                {value ? value : "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "typeSociete",
      label: "TYPE",
      options: {
        display: displayColumn("typeSociete", tableName),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const typeValue = value?.type || "-";
          return (
              <Box
                  data-toggle="tooltip"
                  title={typeValue}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "150px",
                    textAlign: "left",
                  }}
              >
                {typeValue}
              </Box>
          );
        },
      },
    },
    {
      name: "secteur_dactivite",
      label: "SECTEUR D'ACTIVITÉ",
      options: {
        display: displayColumn("secteur_dactivite", tableName),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          const secteur = value?.secteur || "-";
          return (
            <>
              <Box data-toggle="tooltip"
                   title={secteur}
                   style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "150px", textAlign: "left" }}>
                {secteur}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "siteinternet",
      label: "INTERNET",
      options: {
        display: displayColumn("siteinternet", tableName),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box data-toggle="tooltip" title={value} style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden", width: "100px", textAlign: "left" }}>
                {value ? (
                  <a href={value} target="_blank" rel="noreferrer">
                    {value}
                  </a>
                ) : (
                  "-"
                )}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "contacts",
      label: "Nb CONTACTS",
      options: {
        display: displayColumn("contacts", tableName),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              to={{ pathname: `/societe/${tableMeta.rowData[0]}/view?tab=2`}}
              onClick={(event) => {
                event.preventDefault();
                history.push(`/societe/${tableMeta.rowData[0]}/view?tab=2`);
              }}
              aria-haspopup="true"
              data-toggle="tooltip"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "80%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box data-toggle="tooltip" title={value} style={{ width: "100px", textAlign: "left" }}>{value}</Box>
            </Link>
          );
        },
      },
    },
    {
      name: "missions",
      label: "MISSIONS",
      options: {
        display: displayColumn("missions", tableName),
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              to={{ pathname: `/societe/${tableMeta.rowData[0]}/view?tab=1` }}
              onClick={(event) => {
                event.preventDefault();
                history.push(`/societe/${tableMeta.rowData[0]}/view?tab=1`);
              }}
              aria-haspopup="true"
              data-toggle="tooltip"
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "80%",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Box data-toggle="tooltip" title={value} style={{ width: "100px", textAlign: "left" }}>{value}</Box>
            </Link>
          );
        },
      },
    },
    {
        name: "",
        label: "ACTION",
        options: {
          filter: false,
          sort: false,
          display: getUserData()?.role.includes("ROLE_DIRECTION"),
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              textOverFlow: "ellipsis",
              overflow: "hidden",
              maxWidth: "100px",
              textTransform: "capitalize",
            },
          }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton 
                data-toggle="tooltip" 
                title="Supprimer" 
                aria-label="Supprimer" 
                onClick={
                    () => handleOpenDialog({ id: tableMeta.rowData[0], name: `${tableMeta.rowData[1]}` })
                  } 
                style={{ color: "#EF6262" }}
              >
                <Delete />
              </IconButton>
            );
          },
        },
    }
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));

  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 478 : isLargeMode ? viewportHeight - 355 : 200}px`;


  const options = {
    rowsPerPageOptions: [],
    jumpToPage: true,
    page: societesData.pageSociete - 1,
    serverSide: true,
    tableBodyMaxHeight: tableBodyMaxHeight,
    count: societesData.societesCount,
    rowsPerPage: 100,
    textLabels: {
      body: {
        noMatch: societesData.isLoading ? <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: true,
    },
    filter: true,
    filterType: "dropdown",
    responsive: "standard",

  };

  return (
    <>
      <MUIDataTable data={societesData.societes} title="Sociétés" columns={columns} options={options} />
      <DialogDeleteCustom {...dialogDeleteProps} handleDelete={() => handleDeleteSociete(societeSelectedToDelete?.id)} label={`Voulez - vous vraiment supprimer la societe ${societeSelectedToDelete?.name} ?`} />
    </>
  );
};
export default withSnackbar(ListerSociete);
