import {
  CircularProgress, IconButton, useMediaQuery,
} from "@material-ui/core";
import { Delete, LinkedIn, RemoveRedEye } from "@material-ui/icons";
import React from "react";
import { withRouter, Link } from "react-router-dom";
import MUIDataTable from "mui-datatables";
import { Box } from "@material-ui/core";
import "../../Services/SocieteService";
import { useState } from "react";
import { useEffect } from "react";
import { displaySwipeableDrawerSummaryContact } from "../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import { getUserData } from "../../../utils/FunctionHelpers";
import DialogDeleteCustom from "../../../components/DialogDeleteCustom/DialogDeleteCustom";
import { deleteContact } from "../../Services/ContactService";
import { withSnackbar } from "../../../utils/SnackbarHOC";
import { handleOnViewColumnsChange, displayColumn } from "../../../utils/viewColumns";

const ContactList = ({
  contactsData,
  handleSearchContacts,
  snackbarShowMessage,
  parameters
}) => {
  const dispatch = useDispatch()
  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
  const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [contactSelectedToDelete, setContactSelectedToDelete] = useState(null)
  const [loadToDelete, setLoadToDelete] = useState(false)

  const handlePageChange = (params) => {
    handleSearchContacts(params + 1)
  };

  const handleCloseDialog = () => {
    if (loadToDelete) {
      return;
    }
    setOpenDialogDelete(false);
  };

  const handleOpenDialog = (contact) => {
    setContactSelectedToDelete({ id: contact.id, fullName: contact.fullName.charAt(0).toUpperCase() + contact.fullName.slice(1) });
    setOpenDialogDelete(true);
  }

  const handleDeleteContact = async (uuid) => {
    try {
      setLoadToDelete(true);
      snackbarShowMessage("Patientez svp...", "warning", null);
      const data = await deleteContact(uuid)
      handleSearchContacts(contactsData.pageContact, parameters);
      snackbarShowMessage(data);
    } catch (error) {
      snackbarShowMessage("Erreur à la suppression du contact", "error", 4000);
    } finally {
      setLoadToDelete(false);
      setOpenDialogDelete(false);
    }
  };


  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 478 : isLargeMode ? viewportHeight - 355 : 200}px`;

  const dialogDeleteProps = {
    handleClose: handleCloseDialog,
    handleOpen: openDialogDelete,
    loading: loadToDelete,
    title: "Supprimer le contact"
  }

  const tableName = "contactColumnVisibility";
  
  const columns = [
    {
      name: "uuid",
      label: "N°CONTACT",
      options: {
        display: false,
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: "100px",
            textAlign: "left",
          },
        }),
        setCellHeaderProps: () => ({
          style: { textAlign: "center", maxWidth: "100px" },
        }),
        customBodyRender: (value) => {
          return (
            <Box>
              {((value?.substring(0, 20)?.replace(/\D/g, ""))).substring(1, 7)}
            </Box>
          );
        },
      },
    },
    {
      name: "titre",
      label: "TITRE",
      options: {
        display: false,
        customBodyRender: (value) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{ textAlign: "left" }}
            >
              {value}
            </Box>
          );
        },
      },
    },
    {
      name: "prenom",
      label: "PRENOM",
      options: {
        display: displayColumn("prenom", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              {!(isSmall || isMediumMode) && <IconButton
                onClick={() => dispatch(displaySwipeableDrawerSummaryContact({ show: true, idContact: tableMeta.rowData[0], idSociete: tableMeta.rowData[4] }))}
                data-toggle="tooltip"
                title="Voir resumé">
                <RemoveRedEye size={"15px"} /></IconButton>}
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                  marginLeft: "9%",
                }}
              >
                <Link
                  to={{
                    pathname: `/societe/${tableMeta.rowData[4]}/contacts/${tableMeta.rowData[0]}/view`,
                  }}
                  className="d-print-none font-sm"
                  style={{ textDecoration: "none" }}
                >
                  {value}
                </Link>
              </Box>
            </Box>
          );
        },
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        display: displayColumn("nom", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "uppercase",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                <Link
                  to={{
                    pathname: `/societe/${tableMeta.rowData[4]}/contacts/${tableMeta.rowData[0]}/view`,
                  }}
                  className="d-print-none font-sm"
                  style={{ textDecoration: "none" }}
                >
                  {value}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "societeId",
      label: "N°SOCIETE",
      options: {
        display: false,
        customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                  textAlign: "left",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STATUT", 
      options: {
        display: displayColumn("statut", tableName),
        filter: true, sort: true, customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                  textAlign: "left",
                }}
              >
                {value ?? "-"}
              </Box>
            </>
          );
        },
      }
    },
    {
      name: "fonction",
      label: "FONCTION",
      options: {
        display: displayColumn("fonction", tableName),
        customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                  textAlign: "left",
                  textTransform: "capitalize",
                }}
              >
                {value ?? "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "societe",
      label: "SOCIETE",
      options: {
        display: displayColumn("societe", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textTransform: "uppercase",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                <Link
                  to={{ pathname: `/societe/${tableMeta.rowData[4]}/view` }}
                  className="d-print-none font-sm"
                  style={{ textDecoration: "none" }}
                >
                  {value ?? "-"}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "missionsCount",
      label: "MISSIONS",
      options: {
        display: displayColumn("missionsCount", tableName),
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                  textAlign: "center"
                }}
              >
                <Link
                  to={{
                    pathname: `/societe/${tableMeta.rowData[4]}/contacts/${tableMeta.rowData[0]}/view`,
                    state: { tabIndex: 3 },
                  }}
                  className="d-print-none font-sm"
                  style={{ textDecoration: "none" }}
                >
                  {value ?? "-"}
                </Link>
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "mobile",
      label: "MOBILE",
      options: {
        display: displayColumn("mobile", tableName),
        customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                {value}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "telFix",
      label: "TEL PRO",
      options: {
        display: displayColumn("telFix", tableName),
        customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                {value ?? "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "email",
      label: "EMAIL PRO",
      options: {
        display: displayColumn("email", tableName),
        customBodyRender: (value) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {value ?? "-"}
              </Box>
            </>
          );
        },
      },
    },
    {
      name: "linkedIn",
      label: "LINKEDIN",
      options: {
        display: displayColumn("linkedIn", tableName),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <Box
                data-toggle="tooltip"
                title={value}
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "100px",
                }}
              >
                <a href={value} target="_blank" rel="noreferrer">
                  <LinkedIn />
                </a>
              </Box>
            </>
          );
        },
      },
    },
    {
        name: "",
        label: "ACTION",
        options: {
          filter: false,
          sort: false,
          display: getUserData()?.role.includes("ROLE_DIRECTION"),
          setCellProps: () => ({
            style: {
              whiteSpace: "nowrap",
              textOverFlow: "ellipsis",
              overflow: "hidden",
              maxWidth: "100px",
              textTransform: "capitalize",
            },
          }),
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <IconButton data-toggle="tooltip" title="Supprimer" aria-label="Supprimer" onClick={() => handleOpenDialog({ id: tableMeta.rowData[0], fullName: `${tableMeta.rowData[2]}  ${tableMeta.rowData[3]}` })} style={{ color: "#EF6262" }}>
                <Delete />
              </IconButton>
            );
          },
        },
    }
  ];

  const options = {
    rowsPerPageOptions: [],
    page: contactsData.pageContact - 1,
    serverSide: true,
    jumpToPage: true,
    tableBodyMaxHeight: tableBodyMaxHeight,
    count: contactsData.contactsCount,
    rowsPerPage: 100,
    textLabels: {
      body: {
        noMatch: contactsData.isLoading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          handlePageChange(tableState.page);
          break;
        default:
      }
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    selectableRows: "multiple",
    draggableColumns: {
      enabled: false,
    },
    filter: false,
    filterType: "dropdown",
    responsive: "standard",

  };

  return (
    <>
      <MUIDataTable data={contactsData.contacts} title="Contacts" columns={columns} options={options} />
      <DialogDeleteCustom {...dialogDeleteProps} handleDelete={() => handleDeleteContact(contactSelectedToDelete?.id)} label={`Voulez - vous vraiment supprimer le contact ${contactSelectedToDelete?.fullName} ?`} />
    </>
  );
};

export default withRouter(withSnackbar(ContactList));
