import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import {
    ListCandidats,
    CandidatForm,
    FormUser,
    Listeruser,
    FormMission,
    Listermission,
    VisualiserCandidat,
    ContactList,
    VisualiserContact,
    ContactForm,
    visualisermission,
    VisualiserSociete,
    ListerSociete,
    FormSociete,
    Dashboard,
} from "./pages/index";
import ListSelections from "./pages/MesSelections/ListSelections";
import CandidatsListInSelection from "./pages/MesSelections/CandidatsListInSelection";
import CandidatTasks from "./pages/MesTaches/CandidatTasks";
import "./app.css";
import ContactTasks from "./pages/MesTaches/ContactTasks";
import EmailTypeList from "./pages/EmailType/EmailTypeList";
import EmailTypeForm from "./pages/EmailType/EmailTypeForm";
import ReportingClient from "./pages/reporting/ReportingClient";
import CandidatsListInMission from "./pages/Mission/CandidatsListInMission";
import ShowCvCandidat from "./pages/Candidat/VisualiserCandidat/ShowCVCandidat";
import ReportingIntern from "./pages/reporting/reporting-intern/reporting-intern";
import PrivateRoute from "./PrivateRoute";
import Pilotage from "./pages/Pilotage";
import ProcessView from "./pages/Process/Process";
import { v4 } from "uuid";

const PrivatesRoutes = ({ reset, setReset }) => {

    const routes = [
        {
            path: "/accueil",
            exact: true,
            component: () => (
                <Dashboard />
            ),
        },
        {
            path: "/accueil/chercher",
            exact: true,
            component: () => (
                <Dashboard />
            ),
        },
        {
            path: "/",
            exact: true,
            component: () => <Redirect to="/accueil" />,
        },
        {
            path: "/societe/:id/view",
            exact: true,
            component: () => <VisualiserSociete />,
        },
        {
            path: "/societe",
            exact: true,
            component: ListerSociete,
        },
        {
            path: "/societe/add",
            exact: true,
            component: FormSociete,
        },
        {
            path: "/societe/:id/update",
            exact: true,
            component: () => <FormSociete editMode={true} />,
        },
        {
            path: "/candidats",
            exact: true,
            component: ListCandidats,
        },
        {
            path: "/candidats/add",
            exact: true,
            component: CandidatForm,
        },
        {
            path: "/candidats/:idCandidat/edit",
            component: () => <CandidatForm editMode={true} />,
        },
        {
            path: "/candidats/:idCandidat/view",
            component: () => <VisualiserCandidat selected={true} />,
        },
        {
            path: "/societe/:id/missions/:missionId/view",
            exact: true,
            component: visualisermission,
        },
        {
            path: "/users/:id/update",
            exact: true,
            component: () => <FormUser editMode={true} />,
        },
        {
            path: "/societe/:id/missions/add",
            exact: true,
            component: FormMission,
        },
        {
            path: "/societe/:id/missions/:idMission/update",
            exact: true,
            component: () => <FormMission editMode={true} />,
        },
        {
            path: "/users/add",
            exact: true,
            component: FormUser,
        },
        {
            path: "/users",
            exact: true,
            component: Listeruser,
        },
        {
            path: "/candidats/cv/:pdfFileName",
            exact: true,
            component: ShowCvCandidat,
        },
        {
            path: "/contacts",
            exact: true,
            component: ContactList,
        },
        {
            path: "/selections",
            exact: true,
            component: ListSelections,
        },
        {
            path: "/societe/:id/contacts/:idContact/view",
            exact: true,
            component: () => <VisualiserContact />,
        },
        {
            path: "/societe/:id/contacts/:idContact/edit",
            exact: true,
            component: () => <ContactForm editMode={true} />,
        },
        {
            path: "/societe/:id/contacts/add",
            exact: true,
            component: () => <ContactForm />,
        },
        {
            path: "/process",
            exact: true,
            component: () => <ProcessView />,
        },
        {
            path: "/selections/:idSelection/candidats/list",
            exact: true,
            component: CandidatsListInSelection,
        },
        {
            path: "/selections/:idSelection/candidats/:idCandidat/view",
            exact: true,
            component: () => <VisualiserCandidat selected={true} />,
        },
        {
            path: "/tachescandidat",
            exact: true,
            component: CandidatTasks,
        },
        {
            path: "/tachescontact",
            exact: true,
            component: ContactTasks,
        },
        {
            path: "/tachesclient",
            exact: true,
            component: () => <ContactTasks statut={"client"} />,
        },
        {
            path: "/tachesprospect",
            exact: true,
            component: () => <ContactTasks statut={"prospect"} />,
        },
        {
            path: "/emailtype",
            exact: true,
            component: () => <EmailTypeList />,
        },
        {
            path: "/emailtype/add",
            exact: true,
            component: () => <EmailTypeForm />,
        },
        {
            path: "/emailtype/:uuid/edit",
            exact: true,
            component: () => <EmailTypeForm editMode={true} />,
        },
        {
            path: "/missions",
            exact: true,
            component: () => <Listermission />,
        },
        {
            path: "/reportingClient",
            exact: true,
            component: () => <ReportingClient />,
        },
        {
            path: "/pilotage",
            exact: true,
            component: () => <Pilotage />,
        },
        {
            path: "/reporting/intern",
            exact: true,
            component: () => <ReportingIntern />,
        },
        {
            path: "/candidats/missions/:idMission/list",
            exact: true,
            component: () => <CandidatsListInMission />,
        },
    ];

    return <>
        {routes.map((route, index) => (
            <PrivateRoute
                key={v4()}
                path={route.path}
                exact={route.exact}
                component={route.component}
            />
        ))}
    </>
}


export default PrivatesRoutes


