import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { Link } from "react-router-dom";
import AppointmentsListModal from "../../../Mission/Components/AppointmentsListModal";
import ShowCvCandidat from "../../../Candidat/VisualiserCandidat/ShowCVCandidat";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { RemoveRedEye } from "@mui/icons-material";
import { displaySwipeableDrawerSummaryCandidat } from "../../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import { extractDate, filterAppointmentsByStatut } from "../../../../utils/AppointmentsUtils";
import { handleOnViewColumnsChange, displayColumn } from "../../../../utils/viewColumns";
import AppointmentModal from "../../../Candidat/VisualiserCandidat/Tabs/Modals/CandidatProcess/AppointmentModal";
import ShowAddCvCandidat from "../../../Candidat/VisualiserCandidat/ShowAddCvCandidat";

const TableReportingIntern = ({ reportingData, snackbarShowMessage, reloadData, setReloadData }) => {
  const dispatch = useDispatch()
  const apptModalProps = { reloadData, setReloadData };

  const tableName = "reportingColumnVisibility";
  const columns = [
    {
      name: "id",
      label: "ID ",
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: "numCandidat",
      label: "N° CANDIDAT",
      options: {
        filter: true,
        sort: true,
        display: displayColumn("numCandidat", tableName),
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <ShowAddCvCandidat
              {...{ snackbarShowMessage, files: tableMeta.rowData[20] }}
            >
              {value}
            </ShowAddCvCandidat>
          );
        },
      },
    },
    {
      name: "nom",
      label: "NOM",
      options: {
        display: displayColumn("nom", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "200px",
              }}
            >
              {!(isSmall || isMediumMode) && <IconButton
                onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                data-toggle="tooltip"
                title="Voir resumé">
                <RemoveRedEye size={"15px"} /></IconButton>}
              <Link
                to={{ pathname: `/candidats/${tableMeta.rowData[0]}/view` }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "sourceur",
      label: "CONSULTANT",
      options: {
        display: displayColumn("sourceur", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "center",
                width: "140px",
              }}
            >
              {value ?? "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "destinatairePrincipale",
      label: "DESTINATAIRE",
      options: {
        display: displayColumn("destinatairePrincipale", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "center",
                width: "140px",
              }}
            >
              {value ?? "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "nom_societe",
      label: "CLIENT",
      options: {
        display: displayColumn("nom_societe", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "100px",
              }}
            >
              <Link to={{ pathname: `/societe/${tableMeta.rowData[19]}/view` }}>
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "nom_mission",
      label: "MISSION",
      options: {
        display: displayColumn("nom_mission", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "200px",
              }}
            >
              <Link
                to={{
                  pathname: `/societe/${tableMeta.rowData[18]}/missions/${tableMeta.rowData[16]}/view`,
                }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "numMission",
      label: "N° MISSION",
      options: {
        display: displayColumn("numMission", tableName),
        filter: true,
        sort: true,
        setCellHeaderProps: () => ({ style: { whiteSpace: "nowrap" } }),
        customBodyRender: (value, tableMeta) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                width: "40px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Link
                to={{
                  pathname: `/societe/${tableMeta.rowData[18]}/missions/${tableMeta.rowData[16]}/view`,
                }}
              >
                {value}
              </Link>
            </Box>
          );
        },
      },
    },
    {
      name: "statut",
      label: "STEP",
      options: {
        display: displayColumn("statut", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <AppointmentsListModal
              {...{
                appointments: tableMeta.rowData[17],
                step: value,
                filterAppointmentsByStatut,
              }}
            />
          );
        },
      },
    },
    {
      name: "sent",
      label: "ENVOI",
      options: {
        display: displayColumn("sent", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {

          return (
              <Box
                  data-toggle="tooltip"
                  title={value ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
                  style={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    textAlign: "left",
                    width: "90px",
                  }}
              >
                {value ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
              </Box>
          );
        },
      },
    },
    {
      name: "revealed",
      label: "RÉVÉLATION",
      options: {
        display: displayColumn("revealed", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                width: "90px",
              }}
            >
              {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "e1",
      label: "E1",
      options: {
        display: displayColumn("e1", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                width: "90px",
              }}
            >
              {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "e2",
      label: "E2",
      options: {
        display: displayColumn("e2", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                width: "90px",
              }}
            >
              {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "e3",
      label: "E3",
      options: {
        display: displayColumn("e3", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                width: "90px",
              }}
            >
              {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "propale",
      label: "PROPALE",
      options: {
        display: displayColumn("propale", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                width: "90px",
              }}
            >
              {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "integration",
      label: "EMB/INTÉGRATION",
      options: {
        display: displayColumn("integration", tableName),
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Box
              data-toggle="tooltip"
              title={value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                textAlign: "left",
                width: "90px",
              }}
            >
              {value != null ? moment(new Date(value)).format("DD/MM/YYYY") : "-"}
            </Box>
          );
        },
      },
    },
    {
      name: "uuid",
      label: "N°MISSION",
      options: { display: false, filter: true, sort: true },
    },
    {
      name: "appointments",
      options: { display: false, filter: true, sort: true },
    },
    {
      name: "idProcess",
      options: { display: false, filter: true, sort: true },
    },
    {
      name: "client_id",
      options: { display: false, filter: true, sort: true },
    },
    {
      name: "uploadedFiles",
      options: { display: false, filter: true, sort: true },
    },
    {
      name: "actions",
      label: "ACTIONS",
      options: {
        setCellHeaderProps: () => ({ style: { padding: "2px 12px" } }),
        setCellProps: () => ({ style: { padding: "0px 12px" } }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              {tableMeta.rowData[6] ? (
                <AppointmentModal
                  {...apptModalProps}
                  processId={tableMeta.rowData[18]}
                  lastAppointment={tableMeta.rowData[8]}
                />
              ) : (
                ""
              )}
            </>
          )
        }
      },
    },
  ];

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
  const isMediumMode = useMediaQuery((theme) =>
    theme.breakpoints.between(767, 1023)
  );
  const isLargeMode = useMediaQuery((theme) =>
    theme.breakpoints.between(1023, 2800)
  );
  const tableBodyMaxHeight = `${isSmall
    ? viewportHeight - 242
    : isMediumMode
      ? viewportHeight - 510
      : isLargeMode
        ? viewportHeight - 370
        : 200}px`;

  const options = {
    tableBodyMaxHeight: tableBodyMaxHeight,
    rowsPerPage: 50,
    sort: true,
    textLabels: {
      body: {
        noMatch: reportingData.isLoading ? (
          <CircularProgress />
        ) : (
          "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment."
        ),
      },
    },
    onViewColumnsChange: (columnName, action) => {
      handleOnViewColumnsChange(columnName, action, tableName);
    },
    selectableRows: "none",
    draggableColumns: {
      enabled: false,
    },
    responsive: "standard",
  };

  const reportingDataCustom = reportingData?.data?.map((row) => {
    const updatedRow = {
      ...row,
      sent: (row.sent?.date != null) ? moment(row?.sent?.date).toDate().getTime() : null,
      lastAppointment: row.lastAppointment,
      revealed: (row.revealed?.date != null) ? moment(row?.revealed?.date).toDate().getTime() : null,
      integration: extractDate(row?.appointements, /Embauche\/Integration|intégration/),
      propale: extractDate(row?.appointments, /Propale/),
      e1: extractDate(row?.appointments, /RDV 1|E1/),
      e2: extractDate(row?.appointments, /RDV 2|E2/),
      e3: extractDate(row?.appointments, /RDV 3|E3/),
    };
    return updatedRow;
  });

  return (
    <MUIDataTable
      data={reportingDataCustom}
      columns={columns}
      options={options}
    />
  );
};

export default withSnackbar(TableReportingIntern);
