import React, { useState } from "react";
import {
    CircularProgress,
    Box,
    useMediaQuery,
    IconButton,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { useParams, useHistory } from "react-router-dom";
import { getAllCandidatsForSociete } from "../../../Services/CandidatService";
import { LinkedIn, RemoveRedEye } from "@material-ui/icons";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { displaySwipeableDrawerSummaryCandidat } from "../../../../redux/SwipeableDrawer/actions/swipeableDrawerActions";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom";

const CandidatsSociete = ({ snackbarShowMessage }) => {
    const history = useHistory();
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [candidatsSociete, setCandidatsSociete] = useState([]);
    const { id } = useParams();
    const dispatch = useDispatch()
    const isSmall = useMediaQuery((theme) => theme.breakpoints.between(0, 767));
    const isMediumMode = useMediaQuery((theme) => theme.breakpoints.between(767, 1023));
    const isLargeMode = useMediaQuery((theme) => theme.breakpoints.between(1023, 2800));
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
    const tableBodyMaxHeight = `${isSmall ? viewportHeight - 292 : isMediumMode ? viewportHeight - 478 : isLargeMode ? viewportHeight - 355 : '200'}px`;
    const [candidats, setCandidats] = useState([]);

    const viewCandidat = (candidatId, tabIndex) => {
        let candidatsIds = [];
    
        candidats.forEach((candidat) => {
          candidatsIds.push(candidat.id);
        });
    
        history.push({
          pathname: `/candidats/${candidatId}/view`,
          state: {
            tabIndex: tabIndex,
            candidatsInList: candidatsIds,
            candidatIndex: candidatsIds.indexOf(candidatId),
            numbersTotalOfSelections: candidats.length
          },
        });
    };

    React.useEffect(() => {
        const newCandidats = candidatsSociete.map((candidat) => ({
          ...candidat,
          anchorEl: null,
        }));
        setCandidats(newCandidats);
      }, [candidatsSociete]);

    React.useEffect(() => {
        setLoading(true);
        getAllCandidatsForSociete(id).then((data) => {
            setCandidatsSociete(data.data.candidats);
            setCount(data.data.count);
        }).catch((error) => {
            snackbarShowMessage("Erreur de chargement des candidats.", "error", 2000);
        }).finally(() => {
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const columns = [
        {
            name: "id",
            label: "N°",
            options: {
                filter: true,
                sort: true,
                display: false,
                customBodyRender: (value) => {
                    return (
                        <Box data-toggle="tooltip" title={value}>
                            {value}
                        </Box>
                    );
                },
            },
        },
        {
            name: "prenom",
            label: "PRENOM",
            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        textOverFlow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "200px",
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100px",
                            }}
                        >
                            {!(isSmall || isMediumMode) && <IconButton
                                onClick={() => dispatch(displaySwipeableDrawerSummaryCandidat({ show: true, id: tableMeta.rowData[0] }))}
                                data-toggle="tooltip"
                                title="Voir resumé">
                                <RemoveRedEye size={"15px"} /></IconButton>}
                            <Link
                                to={{ pathname: `/candidats/${tableMeta.rowData[0]}/view` }}
                                onClick={(e)=>{
                                    e.preventDefault()
                                    viewCandidat(tableMeta.rowData[0], 0)
                                }}
                            >
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "nom",
            label: "NOM",

            options: {
                filter: true,
                sort: true,

                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        textTransform: "capitalize",
                        textOverFlow: "ellipsis",
                        overflow: "hidden",
                        maxWidth: "200px",
                    },
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box
                            data-toggle="tooltip"
                            title={value}
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100px",
                            }}
                        >
                            <Link
                                to={{ pathname: `/candidats/${tableMeta.rowData[0]}/view` }}
                                onClick={(e)=>{
                                    e.preventDefault()
                                    viewCandidat(tableMeta.rowData[0], 0)
                                }}
                            >
                                {value}
                            </Link>
                        </Box>
                    );
                },
            },
        },
        {
            name: "fonction",
            label: "FONCTION",
            options: {
                setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "30%" } }),
                setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "30%" } }),
            },
        },
        {
            name: "linkedin", label: "LINKEDIN", options: {
                filter: true, sort: true,
                setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center", width: "15%" } }),
                setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "15%" } }),
                customBodyRender: (value) => {
                    return (
                        <>
                            <Box style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "100px"
                            }}>
                                {<a href={value} target="_blank" rel="noreferrer">
                                    <LinkedIn />
                                </a>}
                            </Box>
                        </>
                    );
                },
            }
        },
        {
            name: "seniorite",
            label: "SÉNIORITÉ",
            options: {
                setCellHeaderProps: () => ({ style: { padding: "2px 12px", textAlign: "center" } }),
                setCellProps: () => ({ style: { padding: "0px 12px", whiteSpace: "nowrap", textOverFlow: "ellipsis", overflow: "hidden", width: "15%" } }),
            },
        }
    ];

    const options = {
        tableBodyMaxHeight: "48vh",
        rowsPerPage: 50,
        textLabels: {
            body: {
                noMatch: loading ?
                    <CircularProgress /> : "Désolé, il n'y a aucun enregistrement dans la base de données pour le moment.",
            },
        },
        selectableRows: "none",
        draggableColumns: {
            enabled: true,
        },
        filterType: "dropdown",
        filter: false,
        print: false,
        columns: false,
        responsive: "standard",
        search: false,
        download: false,
        viewColumns: false,
    };

    return (
        <Box sx={{ marginTop: "16px" }}>
            <MUIDataTable
                data={candidatsSociete}
                columns={columns}
                options={options}
                style={{ overflow: "hidden", display: "flex", justifyContent: "center" }}
            />
        </Box>

    );
};

export default withSnackbar(CandidatsSociete);
