const handleOnViewColumnsChange = (columnName, action, tableName) => {
    const visibility = JSON.parse(localStorage.getItem(tableName)) || {};
    const newVisibility = {
        ...visibility,
        [columnName]: action === "add",
    };
    localStorage.setItem(tableName, JSON.stringify(newVisibility));
}
const displayColumn = (columnName, tableName) => {
    const storedVisibility = JSON.parse(localStorage.getItem(tableName)) || {};
    return storedVisibility[columnName] !== undefined ? storedVisibility[columnName] : true;
};

export { handleOnViewColumnsChange, displayColumn };
