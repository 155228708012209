import Modal from "@material-ui/core/Modal";
import React, { useRef, useState } from "react";
import { Backdrop, Box, Button, Fade } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { updateDescription } from "../../../Services/SocieteService";
import { useParams } from "react-router-dom";
import EditorCustom from "../../../../components/EditorCustorm/EditorCustom";
import LoadingButtonCustom from "../../../../components/LoadingButtonCustom/LoadingButtonCustom";
import { withSnackbar } from "../../../../utils/SnackbarHOC";
import { removeLineBreak } from "../../../../utils/EditorUtil";

function getModalStyle() {
  const top = 0;
  const left = 0;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: "20px",
    maxWidth: "600px",
    width: "100%"
  },

  closeButton: {
    color: "#1b2a4e",
    backgroundColor: "#d9e2ef",
    borderColor: "#d9e2ef",
    marginLeft: "0.5em",
    fontWeight: "500",
  },
  modalTitle: {
    color: "rgb(17 122 167)",
  },
}));

function DescriptionModal({ snackbarShowMessage, description, setReloadDescription, reloadDescription }) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({ open: false });
  const [newDescription, setNewDescription] = useState("");
  const [modalStyle] = useState(getModalStyle);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const handleOpen = () => {
    setModalState({ open: true });

    if (description) {
      setNewDescription(description);
    }
  };

  const handleClose = () => {
    if (!loading) {
      setModalState({ open: false });
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = { description: removeLineBreak(newDescription) };
    try {
      snackbarShowMessage("Patientez svp!", "warning", null);
      const response = await updateDescription(data, id);
      snackbarShowMessage(response.data, "success", 4000);
      setReloadDescription(!reloadDescription);
    } catch (error) {
      snackbarShowMessage("Erreur de modification de la description!", "error", 4000);
    } finally {
      setLoading(false);
      handleClose();
    }
  };


  const editorCustomProps = {
    value: newDescription,
    setValue: setNewDescription,
    handleSubmit,
    enableOnBlur: false,
  };

  const loadingButtonProps = {
    handleSubmit: handleSubmit,
    handleUpdate: handleSubmit,
    loading,
    editMode: true,
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="modal-header">
        <h4 className={classes.modalTitle}>Modifier la description</h4>
      </div>
      <div className="modal-body">
        <label className="input-label" htmlFor="description">
          DESCRIPTION
        </label>
        <EditorCustom id="note" {...editorCustomProps} />
      </div>
      <Box sx={{ display: "flex", width: "100%", gap: "10px" }} className="modal-footer">
        <Button className={classes.closeButton} onClick={handleClose} disabled={loading}>
          Annuler
        </Button>
        <LoadingButtonCustom {...loadingButtonProps} />
      </Box>
    </div>
  );

  return (
    <>
      {localStorage.getItem("rolecurrentuser") !== "ROLE_CONSULTANT" && <Button onClick={handleOpen} disabled={loading} style={{ backgroundColor: loading ? "grey" : "rgb(17 122 167)", color: "#fff", marginBottom: "1em" }} size="small" variant={"contained"}>
        Modifier
      </Button>
      }
      <Modal
        className={classes.modal}
        open={modalState.open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalState.open}>{body}</Fade>
      </Modal>
    </>
  );
}

export default withSnackbar(DescriptionModal)
