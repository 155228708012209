import React, {
  useEffect,
  useRef,
  useState
} from "react";
import CandidatTable from "../Candidat/ListCandidats/CandidatTable/index";
import { withRouter, useLocation } from "react-router-dom";
import qs from "query-string";
import { searchCandidats } from "../Services/CandidatService";
import { ContactList } from "../Contact";
import ListerSociete from "../Societe/ListerSociete";
import { rechercherSociete } from "../Services/SocieteService";
import { searchContacts } from "../Services/ContactService";
import Search from "./search";
import { withSnackbar } from "../../utils/SnackbarHOC";
import { updateObjectStateValue } from "../../utils/FunctionHelpers";
import { Box } from "@material-ui/core";
import { LayoutContainer } from "../../layout/LayoutContainer/LayoutContainer";
import { TABLE } from "../../enums/tablesEnum";

const Dashboard = ({
  snackbarShowMessage,
  history
}) => {
  const mounted = useRef(false);
  const [candidatsData, setCandidatsData] = useState({
    loadCandidats: false,
    pageCandidat: 1,
    candidatsCount: 0,
    candidats: [],
    isLoading: false,
    sort: {
      name: "prenom",
      direction: "asc",
    }
  });
  const [societesData, setSocietesData] = useState({
    loadSociete: false,
    pageSociete: 1,
    societesCount: 0,
    societes: [],
    isLoading: false
  });
  const [contactsData, setContactsData] = useState({
    loadContacts: false,
    pageContact: 1,
    contactsCount: 0,
    contacts: [],
    isLoading: true
  });
  const [parameters, setParameters] = useState({});
  const [showTable, setShowTable] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const params = {};
      for (const [key, value] of searchParams.entries()) {
        params[key] = value;
      }
      handleSearch(params);
    } else {
      handleSearch(parameters);
    }
  }, [location.search])


  const handleSearchCandidats = async (page, params = "") => {

    setCandidatsData((prev) => ({
      ...prev,
      candidats: [],
      loadCandidats: true,
      isLoading: true,
      pageCandidat: page
    }))
    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => value !== "")
      );

      const newCandidats = await searchCandidats(qs.stringify(filteredParams), page);
      const candidatsData = {
        loadCandidats: true,
        candidatsCount: newCandidats.data.count,
        candidats: newCandidats.data.candidats,
        isLoading: false,
        pageCandidat: newCandidats.data.page,
        sort: newCandidats.data.sort
      };

      updateObjectStateValue(setCandidatsData, candidatsData);
    } catch (error) {
      snackbarShowMessage("Erreur de chargement des candidats.", "error", 4000);
      updateObjectStateValue(setCandidatsData, {
        isLoading: false,
      });
    }
  };


  const handleSearchSocietes = (page, params = "") => {
    setSocietesData({
      loadSociete: true,
      societesCount: 0,
      societes: [],
      isLoading: true,
      pageSociete: 1
    });

    rechercherSociete(qs.stringify(params), page).then((newSocietes) => {
      setSocietesData({
        loadSociete: true,
        societesCount: newSocietes.data.count,
        societes: newSocietes.data.societes,
        isLoading: false,
        pageSociete: newSocietes.data.page
      });
    }).catch(error => {
      snackbarShowMessage("Erreur de chargement des sociétés.", "error", 4000);
      setSocietesData({
        isLoading: false
      });
    });
  }

  const handleSearchContacts = async (page, params = "") => {
    try {
      setContactsData({
        isLoading: true,
        contactsCount: 0,
        contacts: [],
        isLoading: false,
        pageContact: 1
      });
      const { data } = await searchContacts(qs.stringify(params), page);
      const contactsData = {
        loadContacts: true,
        contactsCount: data.count,
        contacts: data.contacts,
        isLoading: false,
        pageContact: data.page
      };
      updateObjectStateValue(setContactsData, contactsData);
    } catch (error) {
      snackbarShowMessage("Erreur de chargement des contacts.", "error", 4000);
      setContactsData({
        isLoading: false
      });
    }
  };


  const candidatProps = {
    candidatsData,
    handleSearchCandidats,
    parameters,
  };

  const contactProps = {
    contactsData,
    handleSearchContacts,
    parameters
  };

  const societeProps = {
    societesData,
    handleSearchSocietes,
    parameters
  };

  const handleSearch = async (params) => {
    setShowTable(true);
    setParameters(params);
    switch (params.categorie) {
      case TABLE.CANDIDAT:
        handleSearchCandidats(candidatsData.pageCandidat, params);
        break;
      case TABLE.SOCIETE:
        handleSearchSocietes(societesData.pageSociete, params);
        break;
      case TABLE.CONTACT:
        handleSearchContacts(contactsData.pageContact, params);
        break;
      default:
        break;
    }
  };

  const searchProps = {
    mounted,
    candidatsData,
    societesData,
    contactsData,
    handleSearch,
    history,
  };

  const tablesProps = {
    contactProps,
    candidatProps,
    societeProps
  }
  const DisplayTables = ({ props }) => {
    const { contactProps, candidatProps, societeProps } = props
    const { categorie } = parameters
    if (categorie === TABLE.CANDIDAT) {
      return <CandidatTable {...candidatProps} />
    } else if (categorie === TABLE.CONTACT) {
      return <ContactList {...contactProps} />
    } else if (categorie === TABLE.SOCIETE) {
      return <ListerSociete {...societeProps} />
    }
  }

  return (
    <LayoutContainer>
      <Search {...searchProps} />
      <Box sx={{ marginTop: "2rem" }}>
        {showTable && <DisplayTables props={{ ...tablesProps }} />}
      </Box>
    </LayoutContainer>
  );
};

export default withSnackbar(withRouter(Dashboard));
