import React, { useEffect, useRef, useState } from "react";
import { useParams, withRouter, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Card, FormControl, MenuItem, Select, makeStyles, OutlinedInput, InputLabel, Checkbox, ListItemText } from "@material-ui/core";
import "./Modifmission.css";
import "./Addmission.css";
import { addMission, editMission, getDetailsForModification } from "../Services/MissionService";
import { getAllCompetencesMetiersForSelect } from "../Services/CompetenceMetierService";
import { getAllCompetencesSectoriellesForSelect } from "../Services/CompetenceSectorielleService";
import { getAllExperiencesMissionForSelect } from "../Services/ExperienceMissionService";
import { getAllFormationsMissionForSelect } from "../Services/FormationMissionService";
import { getAllNiveauxAnglaisForSelect } from "../Services/NiveauAnglaisService";
import { getAllStatutsMissionForSelect } from "../Services/StatutMissionService";
import { getAllTypesMissionForSelect } from "../Services/TypeMissionService";
import { getConsultantsAndManagers } from "../Services/UserService";
import { getAllContactsInSocieteForMission, getDetails } from "../Services/SocieteService";
import { Box } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import Loader from "../../components/Loader/Loader";
import SaveBar from "../Candidat/CandidatForm/Components/SaveBar";
import { useMediaQuery } from "@material-ui/core";
import { theme } from "../../utils/Theme";
import { withSnackbar } from "../../utils/SnackbarHOC";
import EditorCustom from "../../components/EditorCustorm/EditorCustom";
import { removeLineBreak } from "../../utils/EditorUtil";
import { v4 } from "uuid";

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: "white",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  thirdCard: {
    height: "100%",
  },
  actionArea: {
    marginTop: "1rem",
  },
}));

const FormMission = ({ editMode, history, snackbarShowMessage }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [dataForSelects, setDataForSelects] = useState({
    competencesMetiers: [],
    competencesSecto: [],
    experiencesMission: [],
    formationsMission: [],
    niveauxAnglais: [],
    statutsMission: [],
    typeMissions: [],
    contactsInSociete: [],
    managers: [],
  })
  const [societe, setSociete] = useState({});
  const [nomSociete, setNomSociete] = useState("");

  const mounted = useRef(false);
  const [errors, setErrors] = useState({});
  const [loadingData, setLoadingData] = useState(false);
  const [loadingDataForSelect, setLoadingDataForSelect] = useState(true);
  const { id, idMission } = useParams();
  const [nomMission, setNomMission] = useState("");
  const [statut, setStatut] = useState("");
  const [typeMission, setTypeMission] = useState("");
  const [destinatairePrincipale, setDestinatairePrincipale] = useState("");
  const [destinataireSecondaire, setDestinataireSecondaire] = useState([]);
  const [manager, setManager] = useState("");
  const [consultant, setConsultant] = useState("");
  const [poste, setPoste] = useState("");
  const [experience, setExperience] = useState([]);
  const [formation, setFormation] = useState("72179945-cfda-4e33-8710-7223d18942c8");
  const [sectorielle, setSectorielle] = useState([]);
  const [metier, setMetier] = useState([]);
  const [technique, setTechnique] = useState("");
  const [niveauAnglais, setNiveauAnglais] = useState("9e639474-2d43-4bee-8f7a-66c33afe95ea");
  const [descriptionSociete, setDescriptionSociete] = useState("");
  const [pitchMission, setPitchMission] = useState("");
  const [remuneration, setRemuneration] = useState("");
  const [contactsInSociete, setContactsInSociete] = useState([]);
  const location = useLocation();
  const [priorite, setPriorite] = useState('');


  const validate = () => {
    let temp = {};
    temp.nomMission = nomMission ? "" : "Le champ 'NOM DE LA MISSION' est obligatoire.";
    temp.statut = statut ? "" : "Le champ 'STATUT' est obligatoire.";
    temp.typeMission = typeMission ? "" : "Le champ 'TYPE DE LA MISSION' est obligatoire.";
    temp.principales = destinatairePrincipale.length > 0 ? "" : "1 'DESTINATAIRE PRINCIPALE' est obligatoire";
    temp.priorite = priorite ? "" : "Le champ 'PRIORITE' est obligatoire.";


    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  React.useEffect(() => {
    let isActive = true;
    (async () => {
      setLoading(true);
      try {
        let societeData = await getDetails(id);
        if (!isActive) {
          return;
        }
        setSociete(societeData.data);
        setLoading(false);
      } catch (error) {
        if (!isActive) {
          return;
        }
        setLoading(false);
        history.push({
          pathName: "/accueil",
        });
      }
    })();

    if (!isActive) {
      return;
    }

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let active = true;
    mounted.current = true;
    (async () => {
      setLoadingDataForSelect(true);

      try {
        const [
          metierReponse,
          sectoResponse,
          experienceReponse,
          formationReponse,
          niveauxAnglaisReponse,
          statutMissionReponse,
          typeMissionReponse,
          consultantsManagersResponse,
          contactResponse
        ] = await Promise.all([
          getAllCompetencesMetiersForSelect(),
          getAllCompetencesSectoriellesForSelect(),
          getAllExperiencesMissionForSelect(),
          getAllFormationsMissionForSelect(),
          getAllNiveauxAnglaisForSelect(),
          getAllStatutsMissionForSelect(),
          getAllTypesMissionForSelect(),
          getConsultantsAndManagers(),
          getAllContactsInSocieteForMission(id)
        ]);

        if (!active) {
          return;
        }

        setDataForSelects({
          competencesMetiers: metierReponse.data,
          competencesSecto: sectoResponse.data,
          experiencesMission: experienceReponse.data,
          formationsMission: formationReponse.data,
          niveauxAnglais: niveauxAnglaisReponse.data,
          statutsMission: statutMissionReponse.data,
          typeMissions: typeMissionReponse.data,
          managers: consultantsManagersResponse.data.managers,
          contactsInSociete: contactResponse.data
        });
        setContactsInSociete(contactResponse.data);
        setLoadingDataForSelect(false);
      } catch (error) {
        if (!active) {
          return;
        }
        snackbarShowMessage("Erreur de chargement des listes.", "error", 4000);
        setLoadingDataForSelect(false);
      }

    })();
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let active = true;
    if (editMode) {
      (async () => {
        setLoadingData(true);
        try {
          const response = await getDetailsForModification(idMission);
          if (!active) {
            return;
          }
          setNomMission(response.data.nom_mission);
          setManager(response.data.managerId);
          setConsultant(response.data.consultantId);
          setPoste(response.data.poste);
          setFormation(response.data.formationId);
          setSectorielle(response.data.secteurId);
          setMetier(response.data.metierId);
          setTechnique(response.data.competence_tech);
          setNiveauAnglais(response.data.niveauId);
          setDescriptionSociete(response.data.description_societe);
          setTypeMission(response.data.typeId);
          setStatut(response.data.statutId);
          setDestinatairePrincipale(response.data.principale);
          setDestinataireSecondaire(response.data.secondaires);
          setExperience(response.data.experienceId);
          setRemuneration(response.data.renumeration);
          setPitchMission(response.data.pitch_mission);
          setPriorite(response.data.priorite)
          setLoadingData(false);
        } catch (error) {
          if (!active) {
            return;
          }
          setLoadingData(false);
          history.push({
            pathname: `/societe/${id}/view`,
          });
        }
      })();
    }
    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idMission]);



  const handleSubmit = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const data = {
          nom_mission: nomMission,
          statut,
          priorite,
          type_mission: typeMission,
          societe: id,
          accountManager: manager,
          consultant: consultant,
          destinatairePrincipale: destinatairePrincipale,
          destinataireSecondaire: destinataireSecondaire,
          poste: removeLineBreak(poste),
          experience: experience,
          formation: formation,
          competence_sectrori: sectorielle,
          competence_metiers: metier,
          competence_tech: technique,
          nv_anglais: niveauAnglais,
          descriptionSociete: removeLineBreak(descriptionSociete),
          renumeration: remuneration,
          pitch_mission: removeLineBreak(pitchMission),
        };
        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          const response = await addMission(data);
          snackbarShowMessage("Mission bien ajouté!", "success", 4000)
          if (!mounted.current) {
            return;
          }
          setLoading(false);
          history.push({
            pathname: `/societe/${id}/missions/${response.data.missionId}/view`,
            state: {
              nomSociete,
            },
          });
        } catch (error) {
          if (!mounted.current) {
            return;
          }
          snackbarShowMessage("Une erreur est survenue.", "error", 4000);
        }
      })();
    }
  };

  React.useEffect(() => {
    let isActive = true;
    (async () => {
      setLoading(true);
      try {
        let { data } = await getDetails(id);

        if (!isActive) {
          return;
        }
        setSociete(data);
        setDescriptionSociete(data.description_societe);
        setLoading(false);
      } catch (error) {
        if (!isActive) {
          return;
        }
        setLoading(false);
        history.push({
          pathName: "/accueil",
        });
      }
    })();

    if (!isActive) {
      return;
    }

    return () => {
      isActive = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const checkDestinataireSecondaire = (value) => {
    let contacts = dataForSelects.contactsInSociete.filter((item) => item.uuid !== value);
    setContactsInSociete(contacts);
    if (contacts.length < 1) {
      contacts = "";
    }
  };
  const handleUpdate = () => {
    if (validate()) {
      (async () => {
        setLoading(true);
        const data = {
          nom_mission: nomMission,
          statut,
          priorite,
          type_mission: typeMission,
          societe: id,
          accountManager: manager,
          consultant: consultant,
          destinatairePrincipale: destinatairePrincipale,
          destinataireSecondaire: destinataireSecondaire,
          poste: removeLineBreak(poste),
          experience: experience,
          formation: formation,
          competence_sectrori: sectorielle,
          competence_metiers: metier,
          competence_tech: technique,
          nv_anglais: niveauAnglais,
          descriptionSociete: removeLineBreak(descriptionSociete),
          renumeration: remuneration,
          pitch_mission: removeLineBreak(pitchMission),
        };
        try {
          snackbarShowMessage("Patientez svp!", "warning", null)
          const response = await editMission(data, idMission);
          snackbarShowMessage("Mission a été mise à jour!", "success", 4000)
          if (!mounted.current) {
            return;
          }
          history.push({
            pathname: `/societe/${id}/missions/${response.data.missionId}/view`,
            state: {
              nomSociete,
            },
          });
        } catch (error) {
          if (!mounted.current) {
            return;
          }
          snackbarShowMessage("Une erreur est survenue.", "error", 4000);
          setLoading(false);
        }
      })();
    }
  };

  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));

  const handleChangeExeperince = (event) => {
    setExperience(event.target.value);
  }

  const saveBarProps = {
    handleSubmit,
    editMode,
    handleEdit: handleUpdate,
    loading,
  }

  const editorCustomPitchMissionProps = {
    value: pitchMission,
    setValue: setPitchMission,
    enableOnBlur: false,
  };


  const editorCustomDescriptionProps = {
    value: descriptionSociete,
    setValue: setDescriptionSociete,
    enableOnBlur: false,
  };

  const editorCustomPosteProps = {
    value: poste,
    setValue: setPoste,
    handleSubmit,
    enableOnBlur: false,
  };

  return (
    <Box sx={{ padding: "0 20px " }}>
      {loadingData & editMode ? (
        <Loader />
      ) : (
        <>
          {loadingDataForSelect ? (
            <Loader />
          ) : (
            <>
              <Box className="candidatedit_header">
                <Grid container spacing={1} direction={isSmMode ? "column-reverse" : "row"} >
                  <Grid item xs={12} sm={6} md={6} xl={6}>
                    <h4 style={{ color: "rgb(103 107 132)" }}>{(location?.state?.numMission ? 'N°' + location?.state?.numMission + '-' : "") + societe.nom_societe}</h4>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} xl={6}>
                    <SaveBar item {...saveBarProps} />
                  </Grid>
                </Grid>
              </Box>
              <Box className="candidatedit_container">
                <Grid container spacing={1} alignItems={"stretch"}>
                  <Grid item={true} xs={12} sm={6}>
                    <Card className={classes.firstCard}>
                      <CardContent>
                        <label className="input-label" htmlFor="NOM DE LA MISSION">
                          NOM DE LA MISSION {errors.required && <span className="required-input">*</span>}
                        </label>
                        <TextField
                          name="NOM DE LA MISSION"
                          value={nomMission}
                          onChange={(e) => setNomMission(e.target.value)}
                          placeholder="nom"
                          margin="normal"
                          variant="outlined"
                          autoComplete="off"
                          fullWidth={true}
                          {...(errors.nomMission && { error: true, label: errors.nomMission })}
                        />
                        {[
                          {
                            label: "TYPE DE MISSION",
                            name: "typeMission",
                            value: typeMission,
                            onChange: (e) => setTypeMission(e.target.value),
                            type: "Select",
                            options: dataForSelects.typeMissions,
                            selectLabel: "type",
                            defaultValue: "",
                            fullWidth: true,
                            ...(errors.typeMission && { error: true }),
                          },
                          {
                            label: "STATUT",
                            name: "statut",
                            value: statut,
                            onChange: (e) => setStatut(e.target.value),
                            type: "Select",
                            options: dataForSelects.statutsMission,
                            selectLabel: "statut",
                            defaultValue: "AUTRES",
                            fullWidth: true,
                            ...(errors.statut && { error: true }),
                          },
                          {
                            label: "PRIORITE",
                            name: "priorite",
                            value: priorite,
                            onChange: (e) => setPriorite(e.target.value),
                            type: "Select",
                            options: [{ uuid: '1', label: '1' }, { uuid: '2', label: '2' }, { uuid: '3', label: '3' }],
                            selectLabel: "label",
                            defaultValue: "",
                            fullWidth: true,
                            ...(errors.priorite && { error: true }),
                          },
                          {
                            label: "ACCOUNT MANAGER",
                            name: "manager",
                            value: manager,
                            onChange: (e) => setManager(e.target.value),
                            type: "Select",
                            options: dataForSelects.managers,
                            selectLabel: "user",
                            fullWidth: true,
                            ...(errors.manager && { error: true }),
                          },
                          {
                            label: "CONSULTANT",
                            name: "consultant",
                            value: consultant,
                            onChange: (e) => setConsultant(e.target.value),
                            type: "Select",
                            options: dataForSelects.managers,
                            selectLabel: "user",
                            fullWidth: true,
                            ...(errors.manager && { error: true }),
                          }
                        ].map((field, index) => (
                          <div key={v4()}>
                            <label className="input-label" htmlFor={field.name}>
                              {field.label} {field.required && <span className="required-input">*</span>}
                            </label>
                            {field.type === "Select" && (
                              <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel {...(field.error && { error: true })}>
                                  {field.error ? field.label : ""}
                                </InputLabel>
                                <Select
                                  name={field.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  input={
                                    <OutlinedInput
                                      name={field.name}
                                      {...(field.error && { labelWidth: 340 })}
                                      notched
                                    />
                                  }
                                >
                                  {field.options.map((option) => (
                                    <MenuItem value={option.uuid} key={option.uuid}>
                                      {field.selectLabel ? option[field.selectLabel].toUpperCase() : ""}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </div>
                        ))}
                      </CardContent>
                    </Card>

                  </Grid>
                  <Grid item={true} xs={12} sm={6}>
                    <Card className={classes.thirdCard}>
                      <CardContent>
                        {[
                          {
                            label: "DESTINATAIRES PRINCIPAUX",
                            name: "principales",
                            value: destinatairePrincipale,
                            onChange: (e) => {
                              setDestinatairePrincipale(e.target.value);
                              checkDestinataireSecondaire(e.target.value);
                            },
                            type: "Select",
                            options: dataForSelects.contactsInSociete,
                            selectLabel: "nom",
                            defaultValue: "",
                            fullWidth: true,
                            ...(errors.principales && { error: true }),
                          },
                          {
                            label: "DESTINATAIRES SECONDAIRES",
                            name: "secondaires",
                            value: destinataireSecondaire,
                            onChange: (e) => setDestinataireSecondaire(e.target.value),
                            type: "SelectMultiple",
                            options: contactsInSociete.filter(item => item.uuid.toUpperCase() != destinatairePrincipale.toLocaleUpperCase()),
                            multipleRender: (selected) =>
                              dataForSelects.contactsInSociete
                                .filter((item) => selected.includes(item.uuid))
                                .map((consultant) => consultant.nom)
                                .join(", "),
                            fullWidth: true,
                          },
                          {
                            label: "EXPERIENCE",
                            name: "experience",
                            value: experience,
                            onChange: handleChangeExeperince,
                            type: "SelectMultiple",
                            options: dataForSelects.experiencesMission,
                            multipleRender: (selected) =>
                              dataForSelects.experiencesMission
                                .filter((item) => selected.includes(item.uuid))
                                .map((competence) => competence.experience)
                                .join(", "),
                            fullWidth: true,
                            ...(errors.experience && { error: true }),
                          },
                          {
                            label: "FORMATION",
                            name: "formation",
                            value: formation,
                            onChange: (e) => setFormation(e.target.value),
                            type: "Select",
                            options: dataForSelects.formationsMission,
                            selectLabel: "formation",
                            defaultValue: "",
                            fullWidth: true,
                            ...(errors.formation && { error: true }),
                          },
                        ].map((field, index) => (
                          <div key={v4()}>
                            <label className="input-label" htmlFor={field.name}>
                              {field.label} {field.required && <span className="required-input">*</span>}
                            </label>
                            {field.type === "Select" && (
                              <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel {...(field.error && { error: true })}>
                                  {field.error ? field.label : ""}
                                </InputLabel>
                                <Select
                                  name={field.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  input={
                                    <OutlinedInput
                                      name={field.name}
                                      {...(field.error && { labelWidth: 325 })}
                                      notched
                                    />
                                  }
                                >
                                  {field.options.map((option) => (
                                    <MenuItem value={option.uuid} key={option.uuid}>
                                      {field.selectLabel ? option[field?.selectLabel]?.toUpperCase() : ""}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                            {field.type === "SelectMultiple" && (
                              <FormControl variant="outlined" fullWidth margin="normal">
                                <Select
                                  multiple
                                  name={field.name}
                                  value={field.value}
                                  onChange={field.onChange}
                                  renderValue={field.multipleRender}
                                >
                                  {field.options.map((option) => (
                                    <MenuItem value={option.uuid} key={option.uuid}>
                                      <Checkbox checked={field.value.indexOf(option.uuid) > -1} />
                                      <ListItemText primary={field.selectLabel ? option[field?.selectLabel].toUpperCase() : (option.nom || option.experience)} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </div>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item={true} xs={12} sm={6}>
                    <Card className={classes.thirdCard}>
                      <CardContent>
                        <label className="input-label" htmlFor="secto">
                          COMPÉTENCES SECTORIELLES
                        </label>
                        <FormControl variant="outlined" fullWidth margin="normal">
                          <Select
                              name="secto"
                              multiple
                              value={sectorielle}
                              onChange={(e) => setSectorielle(e.target.value)}
                              renderValue={(selected) =>
                                  dataForSelects.competencesSecto
                                      .filter((item) => selected.includes(item.uuid))
                                      .map((competence) => competence.secteur.toUpperCase())
                                      .join(", ")
                              }
                              input={<OutlinedInput/>}
                          >
                            {dataForSelects.competencesSecto.map((secto) => (
                                <MenuItem key={secto.uuid} value={secto.uuid}>
                                  <Checkbox checked={sectorielle.indexOf(secto.uuid) > -1}/>
                                  <ListItemText primary={secto.secteur.toUpperCase()}/>
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <label className="input-label" htmlFor="metier">
                          COMPÉTENCES MÉTIERS
                        </label>
                        <FormControl variant="outlined" fullWidth margin="normal">
                          <Select
                              name="metier"
                              multiple
                              value={metier}
                              onChange={(e) => setMetier(e.target.value)} // Make sure `e.target.value` is an array
                              renderValue={(selected) =>
                                  dataForSelects.competencesMetiers
                                      .filter((item) => selected.includes(item.uuid))
                                      .map((competence) => competence.metier.toUpperCase())
                                      .join(", ")
                              }
                              input={<OutlinedInput />}
                          >
                            {dataForSelects.competencesMetiers.map((metierOption) => (
                                <MenuItem key={metierOption.uuid} value={metierOption.uuid}>
                                  <Checkbox checked={metier.indexOf(metierOption.uuid) > -1} />
                                  <ListItemText primary={metierOption.metier.toUpperCase()} />
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <label className="input-label" htmlFor="rémunération">
                          RÉMUNÉRATION
                        </label>
                        <TextField
                            onChange={(e) => setRemuneration(e.target.value)}
                            name="rémunération"
                            value={remuneration}
                            placeholder="RÉMUNÉRATION"
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            {...(errors.remuneration && {
                              error: true,
                              label: errors.remuneration,
                            })}
                        />
                        <label className="input-label" htmlFor="poste">
                          POSTE
                        </label>
                        <EditorCustom {...editorCustomPosteProps} />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item={true} xs={12} sm={6}>
                    <Card>
                      <CardContent>
                        <label className="input-label" htmlFor="technique">
                          COMPÉTENCES TECHNIQUES
                        </label>
                        <TextField
                            onChange={(e) => setTechnique(e.target.value)}
                            name="technique"
                            value={technique}
                            placeholder="COMPÉTENCES TECHNIQUES"
                            margin="normal"
                            variant="outlined"
                            autoComplete="off"
                            fullWidth
                            {...(errors.technique && {
                            error: true,
                            label: errors.technique,
                          })}
                        />
                        <label className="input-label" htmlFor="niveauAnglais">
                          NIVEAU D'ANGLAIS
                        </label>
                        <FormControl variant="outlined" fullWidth margin="normal">
                          <InputLabel
                            {...(errors.niveauAnglais && {
                              error: true,
                            })}
                            shrink
                          >
                            {errors.niveauAnglais}
                          </InputLabel>
                          <Select
                            name="niveauAnglais"
                            value={niveauAnglais}
                            displayEmpty
                            onChange={(e) => setNiveauAnglais(e.target.value)}
                            {...(errors.niveauAnglais && {
                              error: true,
                            })}
                            input={
                              <OutlinedInput
                                name="metier"
                                {...(errors.niveauAnglais && {
                                  labelWidth: 320,
                                })}
                                notched
                              ></OutlinedInput>
                            }
                          >
                            {dataForSelects.niveauxAnglais.map((niveau) => (
                              <MenuItem value={niveau.uuid} key={niveau.uuid}>
                                {niveau.niveau.toUpperCase()}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <label className="input-label" htmlFor="pitch">
                          PITCH MISSION
                        </label>
                        <EditorCustom {...editorCustomPitchMissionProps} />
                        <label className="input-label" htmlFor="description">
                          DESCRIPTION SOCIETE
                        </label>
                        <EditorCustom {...editorCustomDescriptionProps} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
export default withSnackbar(withRouter(FormMission));
