import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { Box, Card, Grid, Table, TableBody, TableCell, TableContainer, TableRow, useMediaQuery } from "@material-ui/core";
import DescriptionSociete from "./Tabs/Description";
import Mission from "./Tabs/Missions";
import Contact from "./Tabs/CONTACT";
import { useParams, withRouter } from "react-router-dom";
import { getDetails } from "../../Services/SocieteService";
import { Edit, NotesOutlined, People } from "@material-ui/icons";
import { NoteSociete } from "./Tabs/index";
import CandidatsSociete from "./Tabs/CandidatsSociete";
import Loader from "../../../components/Loader/Loader";
import { theme } from "../../../utils/Theme";
import { AccessTime, ContactPage, Description } from "@mui/icons-material";
import { LayoutContainer } from "../../../layout/LayoutContainer/LayoutContainer";
import { GiOnTarget } from "react-icons/gi";
import LabelHtmlToTextCustom from "../../../components/LabelHtmlToTextCustom/LabelHtmlToTextCustom";
import {
  ReflexContainer,
  ReflexSplitter,
  ReflexElement
} from 'react-reflex'
import "./visualiserSociete.css"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min"

const VisualiserSociete = ({ history }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [value, setValue] = useState(Number(queryParams.get("tab")) ?? 0);
  const [societe, setSociete] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const missionsProps = { nomSociete: societe.nom_societe };
  const isSmMode = useMediaQuery(theme.breakpoints.between(0, 480));
  const isMdMode = useMediaQuery(theme.breakpoints.between(481, 992));

  React.useEffect(() => {
    let isActive = true;

    const fetchData = async () => {
      setLoading(true);
      try {
        const societeData = await getDetails(id);

        if (isActive) {
          setSociete(societeData.data);
        }
      } catch (error) {
        if (isActive) {
          history.push({
            pathname: "/accueil",
          });
        }
      } finally {
        if (isActive) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isActive = false;
    };
  }, [id]);


  React.useEffect(() => {
    if (history.location.state && history.location.state.snack) {
      let state = { ...history.location.state };
      delete state.snack;
      history.replace({
        ...history.location,
        state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabs = (event, newValue) => {
    setValue(newValue);
  };

  const editSociete = (id) => {
    history.push({
      pathname: "/societe/" + id + "/update",
    });
  };

  const formatValue = (value) => {
    return [null, "", "null", undefined].includes(value) ? "-" : value;
  }



  return (
    <LayoutContainer sx={{ width: '100%', height: "100%" }}>
      {loading ? (
        <Loader />
      ) : (<Box className="contentWithHeadSociete">
        <ReflexContainer orientation="horizontal">
          <ReflexElement className="left-pane" >
            <Grid item xs={12} >
              <Grid item xs={12} >
                <Card className="d-flex justify-content-between align-items-center p-2 mb-3">
                  <h4 style={{ color: "rgb(103 107 132)" }}>
                    {`${societe.nom_societe} ${societe?.accountManager ? '- Suivi par ' + societe.accountManager.accountManager : ''}`}
                  </h4>
                  <Box style={{ padding: "10px", cursor: "pointer" }}>
                    <Edit data-toggle="tooltip"
                      title="Modifier" onClick={() => editSociete(id)} style={{ color: "#117aa7" }} />
                  </Box>
                </Card>
                <TableContainer style={{ minWidth: "100%" }}>
                  <Box className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
                    {[
                      {
                        title: "INFORMAÇÕES GERAIS",
                        rows: [
                          { label: "STATUT", value: formatValue(societe.statut) },
                          { label: "N° TEL STANDARD", value: formatValue(societe.n_tel) },
                          { label: "ADRESSE N°1", value: formatValue(societe.adresse) },
                          { label: "CODE POSTAL", value: formatValue(societe.code_postal) },
                          { label: "LINKEDIN", value: formatValue(societe.linkedin), link: true },
                          { label: "VILLE", value: formatValue(societe.ville) },
                        ],
                      },
                      {
                        title: "OUTRAS INFORMAÇÕES",
                        rows: [
                          { label: "RÉGION", value: formatValue(societe.region?.name) },
                          { label: "SITE INTERNET", value: formatValue(societe.site_internet) },
                          { label: "TYPE DE SOCIÉTÉ", value: formatValue(societe.type_societe?.type) },
                          { label: "EFFECTIF", value: formatValue(societe.effectif?.type) },
                          { label: "SECTEUR D'ACTIVITÉ", value: formatValue(societe.secteur_dactivite?.secteur) },
                          { label: "SACHEZ QUE", value: formatValue(societe.sachez_que) },
                        ],
                      }
                    ].map((section, index) => (
                      <div
                        key={section.label + index}
                        className={`col-sm-6 col-12 ${index === 0 ? "pl-0" : "pr-0"}`}
                        style={{ backgroundColor: "rgb(17 72 167 / 4%)" }}
                      >
                        <Table className="table table-striped">
                          <TableBody component="tbody">
                            {section.rows.map((row, rowIndex) => (
                              <TableRow key={rowIndex + row.value}>
                                <TableCell scope="row" style={{ fontSize: "12.8px", width: "50%" }}>
                                  <strong>{row.label}</strong>
                                </TableCell>
                                <TableCell align="right" style={{ fontSize: "12.8px", transform: ["SITE INTERNET", "LINKEDIN", "CODE POSTAL"].includes(row.transform) ? "capitalize" : "" }}>
                                  {row.link ? (
                                    <a href={row.value} target="_blank" rel="noreferrer">
                                      {row.value}
                                    </a>
                                  ) : (
                                    <LabelHtmlToTextCustom data={row.value} />
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    ))}
                  </Box>
                </TableContainer>
              </Grid>
            </Grid>
          </ReflexElement>
          <ReflexSplitter style={{ cursor: "move" }}>
            <Box
              sx={{
                backgroundColor: "#117aa7",
                color: "white",
              }}>
              <Tabs value={value} onChange={handleTabs}>
                {[
                  { label: "NOTES", icon: <NotesOutlined /> },
                  { label: "MISSIONS", icon: <GiOnTarget /> },
                  { label: "CONTACTS", icon: <ContactPage /> },
                  { label: "DESCRIPTION", icon: <Description /> },
                  { label: "CANDIDATS", icon: <People /> },
                ].map((tab, index) => (<Tab key={index + tab.label} style={{ minWidth: isSmMode ? 50 : isMdMode ? 60 : 64 }} label={
                  <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }} >
                    {tab.icon}
                    {!(isSmMode || isMdMode) && <span>{tab.label}</span>}
                  </Box>} />))}
              </Tabs>
            </Box>
          </ReflexSplitter >
          <ReflexElement className="right-pane" maxSize={600} minSize={window.innerHeight - 420} style={{ overflow: "hidden" }}>
            <Box sx={{ backgroundColor: "#f4f7fc", marginTop: "50px" }}>
              {[
                <NoteSociete />,
                <Mission {...missionsProps} />,
                <Contact nomSociete={societe.nom_societe} />,
                <DescriptionSociete />,
                <CandidatsSociete />,
              ].map((component, index) => (
                <TabPanel key={index + value} value={value} index={index}>
                  {component}
                </TabPanel>
              ))}
            </Box>
          </ReflexElement>
        </ReflexContainer>
      </Box>
      )}
    </LayoutContainer>
  );
};
export default withRouter(VisualiserSociete);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </div>
  );
}
